import { BiExit } from "react-icons/bi";

import { BannerIcon } from "../../assets/icon/BannerIcon";
import { BrandIcon } from "../../assets/icon/BrandIcon";
import { CategoryIcon } from "../../assets/icon/CategoryIcon";
import { CitiesIcon } from "../../assets/icon/CitiesIcon";
import { ProductIcon } from "../../assets/icon/ProductIcon";
import { PromocodeIcon } from "../../assets/icon/PromocodeIcon";
import { SpecialistPromocodeIcon } from "../../assets/icon/SpecialistPromocodes";
import { ProtocolIcon } from "../../assets/icon/ProtocolIcon";
import { SeminarsIcon } from "../../assets/icon/SeminarsIcon";
import { LectureIcon } from "../../assets/icon/LectureIcon";
import { UsersIcon } from "../../assets/icon/UsersIcon";
import { OrderIcon } from "../../assets/icon/OrderIcon";
import { SettingsIcon } from "../../assets/icon/SettingsIcon";
import { checkRoleAccess } from "../../utils/roles";
import { USER_ROLES_ACCESS } from "../../common/constants";

const linkItems = [
  {
    name: "Заявки",
    icon: UsersIcon,
    link: "/users",
    checkRoleAccess: (currentRole) => checkRoleAccess(currentRole, USER_ROLES_ACCESS.ADMIN_ONLY),
  },
  {
    name: "Продукты",
    icon: ProductIcon,
    link: "/products",
    checkRoleAccess: (currentRole) => checkRoleAccess(currentRole, USER_ROLES_ACCESS.ADMIN_ONLY),
  },
  {
    name: "Листы ожиданий",
    icon: ProductIcon,
    link: "/wishlist",
    checkRoleAccess: (currentRole) => checkRoleAccess(currentRole, USER_ROLES_ACCESS.ADMIN_ONLY),
  },
  {
    name: "Пользователи",
    icon: UsersIcon,
    link: "/clients",
    checkRoleAccess: (currentRole) => checkRoleAccess(currentRole, USER_ROLES_ACCESS.ADMIN_ONLY),
  },
  {
    name: "Категории",
    icon: CategoryIcon,
    link: "/categories",
    checkRoleAccess: (currentRole) => checkRoleAccess(currentRole, USER_ROLES_ACCESS.ADMIN_ONLY),
  },
  {
    name: "Города",
    icon: CitiesIcon,
    link: "/cities",
    checkRoleAccess: (currentRole) => checkRoleAccess(currentRole, USER_ROLES_ACCESS.ADMIN_ONLY),
  },
  {
    name: "Бренды",
    icon: BrandIcon,
    link: "/brands",
    checkRoleAccess: (currentRole) => checkRoleAccess(currentRole, USER_ROLES_ACCESS.ADMIN_ONLY),
  },
  {
    name: "Протоколы",
    icon: ProtocolIcon,
    link: "/protocols",
    checkRoleAccess: (currentRole) => checkRoleAccess(currentRole, USER_ROLES_ACCESS.ADMIN_ONLY),
  },
  {
    name: "Заказы",
    icon: OrderIcon,
    link: "/orders",
    checkRoleAccess: (currentRole) => checkRoleAccess(currentRole, USER_ROLES_ACCESS.ALL),
  },
  {
    name: "Баннеры",
    icon: BannerIcon,
    link: "/banners",
    checkRoleAccess: (currentRole) => checkRoleAccess(currentRole, USER_ROLES_ACCESS.ADMIN_ONLY),
  },
  {
    name: "Семинары",
    icon: SeminarsIcon,
    link: "/seminars",
    checkRoleAccess: (currentRole) => checkRoleAccess(currentRole, USER_ROLES_ACCESS.ADMIN_ONLY),
  },

  {
    name: "Промокоды",
    icon: PromocodeIcon,
    link: "/promocode",
    checkRoleAccess: (currentRole) => checkRoleAccess(currentRole, USER_ROLES_ACCESS.ADMIN_ONLY),
  },
  {
    name: "Промокоды специалистов",
    icon: SpecialistPromocodeIcon,
    link: "/specialist-promocodes",
    checkRoleAccess: (currentRole) => checkRoleAccess(currentRole, USER_ROLES_ACCESS.ADMIN_ONLY),
  },
  {
    name: "Настройки",
    icon: SettingsIcon,
    link: "/settings",
    checkRoleAccess: (currentRole) => checkRoleAccess(currentRole, USER_ROLES_ACCESS.ALL),
  },
];

export { BiExit, linkItems };

export const MAX_NOTIFICATION_DISPLAY = 99;
