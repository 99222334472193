import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import { Flex, Input, Box, Button } from "@chakra-ui/react";
import { Pagination } from "../../components/Pagination";
import { WishlistTable } from "../../components/WishlistTable/WishlistTable";
import { WarehousesAutocomplete } from "../../components/WarehousesAutocomplete/WarehousesAutocomplete";
import { RoleSelect } from "../../components/RoleSelect/RoleSelect";
import { useDebounceCallback } from "../../hooks/useDebounceCallback";
import { usePagination } from "../../hooks/usePagination";
import { getWishlist } from "./store/Analytics.selectors";
import { createReportWishListThunk, getWishlistThunk } from "./store/Analytics.thunk";
import { EXPORT_FILE_BTN } from "../Products/style";

export const WishlistPage = () => {
  const [querySearch, setQuerySearch] = useState("");
  const [idOfSearchingWarehouse, setIdOfSearchingWarehouse] = useState("");
  const [searchingRole, setSearchingRole] = useState(null);
  const dispatch = useDispatch();
  const BASE_DEBOUNCE_DELAY = 1000;
  const wishlistItems = useSelector(getWishlist());
  const [isLoading, setIsLoading] = useState(false);

  const handleExportToExcel = async () => {
    try {
      setIsLoading(true);
      const params = {};
      if (searchingRole?.value) params.role = searchingRole.value;
      if (idOfSearchingWarehouse?.id) params.warehouseId = idOfSearchingWarehouse.id;

      await dispatch(createReportWishListThunk(params)).unwrap();
    } catch (e) {
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }
  };

  const {
    isPageValid,
    isPrevDisabled,
    isNextDisabled,
    page,
    limit,
    totalPages,
    nextPage,
    prevPage,
    setPage,
    setLimit,
  } = usePagination({
    total: wishlistItems?.count,
  });

  const requestWishlist = async (query) => {
    dispatch(
      getWishlistThunk({
        limit,
        skip: (page - 1) * limit,
        query: query ?? querySearch,
        ...(idOfSearchingWarehouse && { warehouseId: idOfSearchingWarehouse.id }),
        ...(searchingRole && { role: searchingRole.value }),
      })
    ).unwrap();
  };

  const request = useMemo(() => requestWishlist, []);

  useEffect(() => {
    requestWishlist();
  }, [limit, page, idOfSearchingWarehouse, searchingRole]);

  const debounceRequest = useDebounceCallback(request, BASE_DEBOUNCE_DELAY, []);

  const valueSetter = (value) => {
    debounceRequest(value);

    setQuerySearch(value);
  };

  return (
    <PageLayout>
      <Flex alignItems="flex-start" marginBottom="8">
        <Button
          onClick={handleExportToExcel}
          style={EXPORT_FILE_BTN}
          colorScheme="purple"
          isLoading={isLoading}
          aria-label="Export"
        >
          Сформировать отчет
        </Button>
      </Flex>
      <Flex gap={1} position="relative" marginBottom="15px" justify="space-between">
        <Box minWidth="70px" textAlign="start">
          <Input
            value={querySearch}
            onChange={(e) => valueSetter(e.target.value)}
            placeholder="Введите название товара"
          />
        </Box>

        <Box minWidth="70px" textAlign="start">
          <RoleSelect
            value={searchingRole}
            placeholder="Роль не указана"
            onChange={(value) => setSearchingRole(value)}
          />
        </Box>

        <Box minWidth="70px" textAlign="start">
          <WarehousesAutocomplete
            value={idOfSearchingWarehouse}
            handleChange={(value) => setIdOfSearchingWarehouse(value)}
            isDisabled={false}
            placeholder="Склад не указан"
          />
        </Box>

        <Pagination
          totalPages={totalPages}
          page={page}
          limit={limit}
          isPrevDisabled={isPrevDisabled}
          isNextDisabled={isNextDisabled}
          onNextPage={nextPage}
          onPrevPage={prevPage}
          onChangePage={setPage}
          onLimitChange={setLimit}
        />
      </Flex>
      <WishlistTable />
    </PageLayout>
  );
};
